import { createContext, useState } from 'react';

type Relationship = {
  name?: string;
  email?: string;
  username?: string;
  dueDate?: any;
  relationshipType?: string;
  fromSignup?: boolean;
  signupEmail?: string;
};

type RelationshipContextType = {
  relationship: Relationship | null;
  updateRelationship: (relationship: Relationship | null) => void;
};

export const RelationshipContext = createContext<RelationshipContextType>({
  relationship: null,
  updateRelationship: () => {},
});

export const RelationshipProvider = ({ children }: any) => {
  const [relationship, setRelationship] = useState<Relationship | null>(null);

  return (
    <RelationshipContext.Provider value={{ relationship, updateRelationship: setRelationship }}>
      {children}
    </RelationshipContext.Provider>
  );
};
