import { CrossCircledIcon } from '@radix-ui/react-icons';
import { ReactNode } from 'react';

export default function Modal({
  children,
  showCloseIcon,
  position = 'left',
  closeModal,
}: {
  children: ReactNode;
  showCloseIcon?: boolean;
  position?: string;
  closeModal?: () => void;
}) {
  return (
    <div className="absolute inset-0 bg-black/30">
      <div className="absolute w-full max-w-sm px-4 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <div className="shadow-md rounded-xl bg-white">
          {showCloseIcon && (
            <div className={`py-4 px-4 cursor-pointer flex ${position === 'right' ? 'justify-start' : 'justify-end'}`}>
              <CrossCircledIcon className="size-6" onClick={closeModal} />
            </div>
          )}
          <div className="px-5 pt-4 pb-10 overflow-y-auto">{children}</div>
        </div>
      </div>
    </div>
  );
}
