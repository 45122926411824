import { useContext, useEffect, useState } from 'react';
import { Button } from '../../components/button';
import AuthCheckHelper from 'src/helpers/AuthCheckHelper';
import { RelationshipContext } from 'src/context/RelationshipContext';
import { useNavigate } from 'react-router-dom';
import { checkUserNameApiHandler } from 'src/api-handler/username';
import { ArrowLeftIcon } from '@radix-ui/react-icons';

export default function EmailSetup() {
  const navigate = useNavigate();
  const { relationship, updateRelationship } = useContext(RelationshipContext);

  // remove all spaces and make it lowercase
  const name = relationship?.name?.replace(/\s/g, '').toLowerCase();
  const [username, setUsername] = useState(name);
  const [isLoading, setIsLoading] = useState(false);

  const checkUsername = async (username: string) => {
    setIsLoading(true);
    const response = await checkUserNameApiHandler(username);
    if (response?.error) {
      alert(response.error);
      setIsLoading(false);
      return;
    }
    if (!response.exists) {
      updateRelationship({
        ...relationship,
        username: username,
        email: `${username}@notestofutureyou.com`,
      });
      navigate('/relation-setup');
    }

    setIsLoading(false);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (username) {
      checkUsername(username);
    }

    // Handle form submission
    console.log('Submitted email:', username);

    // navigate('/relation-setup');
  };

  useEffect(() => {
    if (!relationship?.name) {
      navigate('/setup');
    }
  }, []);

  return (
    // <AuthCheckHelper auth>
    <div className="px-6 py-11 flex flex-col items-center justify-between relative h-full">
      <ArrowLeftIcon className="size-6 absolute self-start md:hidden cursor-pointer" onClick={() => navigate(-1)} />

      <div className="pt-20 w-full max-w-xl flex flex-col md:justify-center flex-grow">
        <div className="flex flex-col items-center md:items-start gap-2">
          <h1 className="text-2xl md:text-4xl text-black font-bold">
            Start <span className="capitalize">{relationship?.name}&apos;s</span> story
          </h1>

          <div className="w-[100px] h-0.5 bg-black/10 rounded-xl block md:hidden">
            <div className="bg-success h-full w-2/5 rounded-xl"></div>
          </div>
        </div>

        <p className="text-xl mt-7 block md:hidden">
          You&apos;ll receive one prompt a day from the email below and as you respond you&apos;ll create{' '}
          <span className="capitalize">{relationship?.name}</span>
          story and share your love and wisdom
        </p>

        <div className="mt-10 flex flex-col gap-2">
          <p className="hidden md:block">You’ll receive prompts from this email so writing is easy</p>

          <div className="flex items-center w-full px-3 py-2 border border-neutral-300 rounded-3xl text-lg">
            <input
              className="flex-1 xs:w-[80px] md-w-auto outline-none border-none"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />

            <span className="text-neutral-300">@notestofutureyou.com</span>
          </div>
        </div>
      </div>

      <div className="flex justify-center w-full md:hidden">
        <Button
          disabled={username === undefined || username?.trim() === '' || isLoading}
          className="py-4 px-12 w-full text-white text-xl rounded-3xl bg-primary-purple hover:bg-primary-purple/80 disabled:bg-primary-purple/50"
          onClick={handleSubmit}
        >
          {isLoading ? 'Checking...' : 'Looks Good'}
        </Button>
      </div>

      <div className="hidden md:flex self-end">
        <Button
          disabled={username === undefined || username?.trim() === '' || isLoading}
          className="py-4 px-12 text-white text-xl rounded-3xl bg-primary-purple hover:bg-primary-purple/80 disabled:bg-primary-purple/50"
          onClick={handleSubmit}
        >
          {isLoading ? 'Checking...' : <>Step 2 of {relationship?.fromSignup ? '5' : '4'}</>}
        </Button>
      </div>
    </div>
    // {/* </AuthCheckHelper> */}
  );
}
