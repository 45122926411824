import axios from 'axios';

interface ILoginRequest {
  email: string;
  password: string;
}

export const loginApiHandler = async (body: ILoginRequest) => {
  try {
    const response = await axios.post('/auth/login', body);
    return response.data;
  } catch (error: any) {
    if (error?.response?.status === 401) {
      return { error: 'Invalid email or password' };
    }
    return { error: error?.response?.data?.message || 'Something went wrong' };
  }
};

interface ISignUpRequest {
  name: string;
  email: string;
  password: string;
}

export const signApiHandler = async (body: ISignUpRequest) => {
  try {
    const response = await axios.post('/auth/register', body);
    return response.data;
  } catch (error: any) {
    return { error: error?.response?.data?.message || 'Something went wrong' };
  }
};

export const getCurrentUserApiHandler = async () => {
  try {
    const response = await axios.get('/user/me');
    return response.data;
  } catch (error: any) {
    return { error: error?.response?.data?.message || 'Something went wrong' };
  }
};

export const updateCurrentUserApiHandler = async (data: any) => {
  try {
    const response = await axios.put('/user/me', data);
    return response.data;
  } catch (error: any) {
    return { error: error?.response?.data?.message || 'Something went wrong' };
  }
};
