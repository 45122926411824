import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getRelationsApiHandler } from 'src/api-handler/relations';
import Layout from 'src/components/layout';
import { RelationshipContext } from 'src/context/RelationshipContext';
import AuthCheckHelper from 'src/helpers/AuthCheckHelper';
import { ChevronRight, ArrowLeft, Plus } from 'lucide-react';
import { AuthContext } from 'src/context/AuthContext';

export default function RelationList() {
  const navigate = useNavigate();
  const { updateRelationship } = useContext(RelationshipContext);
  const { user } = useContext(AuthContext);

  const [list, setList] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  const [selectedContact, setSelectedContact] = useState<any>(undefined);

  const fetchList = async () => {
    try {
      const response = await getRelationsApiHandler();
      if (response?.length) {
        setList(response);
      } else {
        navigate('/setup');
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchList();
  }, [user]);

  useEffect(() => {
    updateRelationship(null);
    if (list.length > 0) {
      navigate(`/notes?username=${list[0].username}`);
    }
    if (!user?.subscription) {
      navigate('/choose-plan');
    }
  }, [list]);

  return (
    <AuthCheckHelper auth>
      <div>Loading...</div>
    </AuthCheckHelper>
  );

  // return (
  //   <AuthCheckHelper auth>
  //     <div className="p-5">
  //       {/* <Layout> */}
  //       <div className="">
  //         <div className="min-h-[100vh] flex flex-col bg-white pt-5">
  //           <div className="w-full flex flex-wrap gap-5 flex-grow-0 md:flex-row sm:flex-col xs:flex-col">
  //             {loading ? (
  //               <div className="p-10">Loading...</div>
  //             ) : (
  //               <>
  //                 {selectedContact ? (
  //                   <>
  //                     <div className="p-2">
  //                       <ArrowLeft size={32} className="cursor-pointer" onClick={() => setSelectedContact(undefined)} />
  //                     </div>
  //                     <div
  //                       onClick={() => {
  //                         navigate(`/notes?username=${selectedContact.username}`);
  //                       }}
  //                       className="flex gap-5 flex-col justify-between p-4"
  //                     >
  //                       <div className="text-center text-2xl font-bold">{selectedContact.name}</div>
  //                       <div className="text-center">{selectedContact.username}@notestofutureyou.com</div>
  //                       <div className="flex justify-between">
  //                         <div className="text-center text-lg">Relationship</div>
  //                         <div className="text-center capitalize text-gray-500 text-sm">
  //                           {selectedContact.name_of_relation}
  //                         </div>
  //                       </div>
  //                       <div className="flex justify-between">
  //                         <div className="text-center text-lg">Daily prompts</div>
  //                         {/* <div className="text-center text-gray-500">{item.daily_prompts_time}</div> */}
  //                         <div className="text-center text-gray-500 text-sm">daily, 7am CST</div>
  //                       </div>
  //                       <div className="flex justify-between">
  //                         <div className="text-center text-lg">Other prompts</div>
  //                         <div className="text-center text-gray-500 text-sm">Off</div>
  //                       </div>
  //                       <div className="flex justify-between">
  //                         <div className="text-center text-lg">Saved notes</div>
  //                         <div className="text-center text-gray-500 text-sm">{selectedContact.messages} notes</div>
  //                       </div>
  //                       <div className="flex justify-between">
  //                         <div className="text-center text-lg">Sharing</div>
  //                         {/* <div className="text-center text-gray-500">{item.due_date}</div> */}
  //                         <div className="text-center text-gray-500 text-sm">not started</div>
  //                       </div>
  //                     </div>
  //                   </>
  //                 ) : (
  //                   <>
  //                     {list.map((item: any) => (
  //                       <>
  //                         <div
  //                           onClick={() => {
  //                             navigate(`/notes?username=${item.username}`);
  //                           }}
  //                           className="md:w-[400px] md:flex sm:hidden xs:hidden cursor-pointer h-[400px] flex flex-col justify-between p-4 border-2 border-black"
  //                         >
  //                           <div className="text-center text-2xl font-bold">{item.name}</div>
  //                           <div className="text-center">{item.username}@notestofutureyou.com</div>
  //                           <div className="flex justify-between">
  //                             <div className="text-center">Relationship</div>
  //                             <div className="text-center capitalize text-gray-500">{item.name_of_relation}</div>
  //                           </div>
  //                           <div className="flex justify-between">
  //                             <div className="text-center">Daily prompts</div>
  //                             {/* <div className="text-center text-gray-500">{item.daily_prompts_time}</div> */}
  //                             <div className="text-center text-gray-500">daily, 7am CST</div>
  //                           </div>
  //                           <div className="flex justify-between">
  //                             <div className="text-center">Other prompts</div>
  //                             <div className="text-center text-gray-500">Off</div>
  //                           </div>
  //                           <div className="flex justify-between">
  //                             <div className="text-center">Saved notes</div>
  //                             <div className="text-center text-gray-500">{item.messages} notes</div>
  //                           </div>
  //                           <div className="flex justify-between">
  //                             <div className="text-center">Sharing</div>
  //                             {/* <div className="text-center text-gray-500">{item.due_date}</div> */}
  //                             <div className="text-center text-gray-500">not started</div>
  //                           </div>
  //                         </div>
  //                         <div
  //                           className="md:hidden sm:flex xs:flex gap-7 cursor-pointer"
  //                           onClick={() => {
  //                             setSelectedContact(item);
  //                           }}
  //                         >
  //                           <div className="h-16 w-16 bg-slate-300 rounded-full" />
  //                           <div className="flex-1 flex justify-between items-center">
  //                             <div>
  //                               <div className="text-2xl font-normal">{item.name}</div>
  //                               <div className="text-sm font-normal">{item.username}@notestofutureyou.com</div>
  //                             </div>
  //                             <ChevronRight size={24} />
  //                           </div>
  //                         </div>
  //                       </>
  //                     ))}

  //                     <div
  //                       onClick={() => {
  //                         navigate('/setup');
  //                       }}
  //                       className="md:w-[400px] md:flex sm:hidden xs:hidden h-[400px] p-4 border-2 border-black flex items-center justify-center text-gray-300 text-[240px] cursor-pointer"
  //                     >
  //                       +
  //                     </div>
  //                     <div
  //                       className="md:hidden sm:flex xs:flex gap-7 cursor-pointer"
  //                       onClick={() => {
  //                         navigate('/setup');
  //                       }}
  //                     >
  //                       <div className="h-16 w-16 bg-black rounded-full flex justify-center items-center">
  //                         <Plus size={38} className="text-white" />
  //                       </div>
  //                     </div>
  //                   </>
  //                 )}
  //               </>
  //             )}
  //           </div>
  //         </div>
  //       </div>
  //       {/* </Layout> */}
  //     </div>
  //   </AuthCheckHelper>
  // );
}
