import axios from 'axios';

export const checkUserNameApiHandler = async (username: string) => {
  try {
    const response = await axios.get(`/mailcow/check-username/${username}`);
    return response.data;
  } catch (error: any) {
    return { error: error?.response?.data?.message || 'Something went wrong' };
  }
};
