import { ArrowLeftIcon, CheckIcon } from '@radix-ui/react-icons';
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';

import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { confirmSubscription, createSubscriptions } from 'src/api-handler/subscriptions';
import { Button } from 'src/components/button';
import { AuthContext } from 'src/context/AuthContext';
import AuthCheckHelper from 'src/helpers/AuthCheckHelper';

export default function ChoosePlan() {
  const navigate = useNavigate();

  const { fetchUser, user } = useContext(AuthContext);

  const [plan, setPlan] = useState('plan-1');
  const [startPayment, setStartPayment] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);

  const elements = useElements();
  const stripe = useStripe();

  const handleSubmit = async (e: React.FormEvent) => {
    setPaymentLoading(true);
    e.preventDefault();

    if (!stripe || !elements) return;

    try {
      const paymentMethod = await stripe.createPaymentMethod({
        type: 'card',
        card: elements?.getElement(CardNumberElement)!,
        billing_details: {
          email: user?.email,
        },
      });

      const response = await createSubscriptions({
        paymentMethod: paymentMethod?.paymentMethod?.id,
        email: user?.email,
        type: plan === 'plan-1' ? 'M' : 'Y',
      });

      const confirmPayment = await stripe?.confirmCardPayment(response.clientSecret);

      await confirmSubscription({
        subscriptionId: response.id,
        type: plan === 'plan-1' ? 'M' : 'Y',
      });

      if (confirmPayment?.error) {
        alert(confirmPayment.error.message);
      } else {
        alert('Your subscription has been activated!');
        navigate('/dashboard');
      }
    } catch (error) {
      console.log(error);
    } finally {
      fetchUser();
      setPaymentLoading(false);
    }
  };

  useEffect(() => {
    if (user?.subscription) {
      navigate('/dashboard');
    }
  }, [user]);

  const customStyle = {
    base: {
      fontSize: '16px',
      color: '#424770',
      letterSpacing: '0.025em',
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      '::placeholder': {
        color: '#aab7c4',
      },
      backgroundColor: 'white',
    },
    invalid: {
      color: '#9e2146',
    },
  };

  return (
    <AuthCheckHelper auth>
      <div className="px-6 py-11 flex flex-col items-center justify-between h-full">
        <ArrowLeftIcon className="size-6 absolute self-start md:hidden cursor-pointer" onClick={() => navigate(-1)} />

        <div className="pt-20 pb5 md:pt-12 w-full max-w-xl h-full">
          <div className="flex flex-col items-center justify-between gap-7 h-full">
            <div className="flex flex-col items-center gap-11">
              <h1 className="text-2xl md:text-4xl text-black font-bold">Start your 7 day free trial</h1>

              <ul className="flex flex-col gap-1">
                <li className="flex items-center gap-3">
                  <CheckIcon className="size-4 text-white bg-success rounded-full" />
                  <span className="">Add other kids</span>
                </li>

                <li className="flex items-center gap-3">
                  <CheckIcon className="size-4 text-white bg-success rounded-full" />
                  <span className="">1,000+ prompts</span>
                </li>

                <li className="flex items-center gap-3">
                  <CheckIcon className="size-4 text-white bg-success rounded-full" />
                  <span className="">Customized topics</span>
                </li>

                <li className="flex items-center gap-3">
                  <CheckIcon className="size-4 text-white bg-success rounded-full" />
                  <span className="">Export to book format</span>
                </li>

                <li className="flex items-center gap-3">
                  <CheckIcon className="size-4 text-white bg-success rounded-full" />
                  <span className="">Share notes and inbox</span>
                </li>

                <li className="flex items-center gap-3">
                  <CheckIcon className="size-4 text-white bg-success rounded-full" />
                  <span className="">Create a novel, play, etc. with AI</span>
                </li>

                <li className="flex items-center gap-3">
                  <CheckIcon className="size-4 text-white bg-success rounded-full" />
                  <span className="">
                    AI search for ultra control <span className="text-sm">(coming soon)</span>
                  </span>
                </li>

                <li className="flex items-center gap-3">
                  <CheckIcon className="size-4 text-white bg-success rounded-full" />
                  <span className="">Cancel anytime</span>
                </li>
              </ul>
            </div>

            <div className="flex flex-col items-center gap-5">
              <div className="flex gap-[6px]">
                <Button
                  className={`py-5 px-12 rounded-xl border h-[65px] ${plan === 'plan-1' ? 'border-blue-accent' : 'border-black/10'}`}
                  onClick={() => setPlan('plan-1')}
                >
                  $2.99/mn
                </Button>

                <Button
                  className={`py-5 px-12 rounded-xl border h-[65px] ${plan === 'plan-2' ? 'border-blue-accent' : 'border-black/10'}`}
                  onClick={() => setPlan('plan-2')}
                >
                  $29.99/yr
                </Button>
              </div>

              {startPayment ? (
                <form onSubmit={handleSubmit} style={{ width: 500, opacity: paymentLoading ? 0.5 : 1 }}>
                  <div style={{ marginBottom: '15px' }}>
                    <label htmlFor="cardNumber">Card Number</label>
                    <div style={{ border: '1px solid #ced4da', padding: '10px', borderRadius: '4px' }}>
                      <CardNumberElement id="cardNumber" options={{ style: customStyle, disabled: paymentLoading }} />
                    </div>
                  </div>

                  <div style={{ marginBottom: '15px', display: 'flex', gap: '10px' }}>
                    <div style={{ flex: '1' }}>
                      <label htmlFor="cardExpiry">Expiry Date</label>
                      <div style={{ border: '1px solid #ced4da', padding: '10px', borderRadius: '4px' }}>
                        <CardExpiryElement id="cardExpiry" options={{ style: customStyle, disabled: paymentLoading }} />
                      </div>
                    </div>
                    <div style={{ flex: '1' }}>
                      <label htmlFor="cardCvc">CVC</label>
                      <div style={{ border: '1px solid #ced4da', padding: '10px', borderRadius: '4px' }}>
                        <CardCvcElement id="cardCvc" options={{ style: customStyle, disabled: paymentLoading }} />
                      </div>
                    </div>
                  </div>

                  <Button
                    className="bg-primary-purple hover:bg-primary-purple/80 text-white w-full rounded-3xl"
                    type="submit"
                    // onClick={() => navigate('/success')}
                  >
                    {paymentLoading ? 'Loading...' : 'Pay Now'}
                  </Button>
                </form>
              ) : (
                <Button
                  className="bg-primary-purple hover:bg-primary-purple/80 text-white w-full rounded-3xl"
                  type="submit"
                  onClick={() => setStartPayment(true)}
                  // onClick={() => navigate('/success')}
                >
                  Start
                </Button>
              )}

              <p className="text-sm cursor-pointer" onClick={() => navigate('/terms-and-permissions')}>
                Terms and privacy
              </p>
            </div>
          </div>
        </div>
      </div>
    </AuthCheckHelper>
  );
}
