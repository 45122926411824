import React, { useContext, useEffect, useState } from 'react';
import { Button } from './button';
import folder_icon from '../assets/images/folder_icon.png';
import user_avatar from '../assets/images/user_avatar.png';
import user_list from '../assets/images/user_list.png';
import { AuthContext } from 'src/context/AuthContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AvatarIcon, DotsVerticalIcon, MagnifyingGlassIcon } from '@radix-ui/react-icons';
import Modal from './modal';
import { getRelationsApiHandler } from 'src/api-handler/relations';
import { RelationshipContext } from 'src/context/RelationshipContext';

export default function Layout({ children }: any) {
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext);
  const { updateRelationship } = useContext(RelationshipContext);

  let [searchParams] = useSearchParams();
  const username = searchParams.get('username');

  const [list, setList] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  const [iconColor, setIconColor] = useState('');

  const [showAccountModal, setShowAccountModal] = useState(false);

  const fetchList = async () => {
    try {
      const response = await getRelationsApiHandler();

      if (response?.length) {
        setList(response);
      } else {
        navigate('/setup');
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchList();
    updateRelationship(null);
  }, []);

  useEffect(() => {
    const user = list.find((user: any) => user.username === username);

    if (user) {
      const colorString = `#${user.icon_color}`;
      setIconColor(colorString || 'black');
      console.log({ iconColor });
    }
  }, [username]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="min-h-full h-full flex flex-col gap-7">
      <div className="flex gap-9 h-full w-full">
        <div className="hidden md:flex flex-col items-center border-r border-black/20 px-3  gap-16">
          <div className="py-5">
            <AvatarIcon className="text-[#D9D9D9] size-8 cursor-pointer" onClick={() => navigate('/my-account')} />
          </div>

          <div className="flex flex-col items-center gap-2">
            {list.map((item: any, index: any) => {
              const color = item.icon_color ? `#${item.icon_color}` : 'black';
              const isActive = item.username === username;

              return (
                <div
                  key={index}
                  className="flex flex-col items-center justify-center cursor-pointer"
                  onClick={() => navigate(`/notes?username=${item.username}&relationshipType=${item.name_of_relation}`)}
                >
                  <div
                    className="text-white size-10 rounded-full gridtext-xl text-center flex items-center justify-center"
                    style={{ backgroundColor: color }}
                  >
                    {item.name[0]}
                  </div>
                  <p className={isActive ? 'font-bold text-center' : 'text-center'}>{item.name}</p>
                </div>
              );
            })}

            <div
              className="size-10 rounded-full grid place-items-center text-xl cursor-pointer"
              onClick={() => navigate('/setup')}
            >
              +
            </div>
          </div>
        </div>

        <div className="h-full w-full flex flex-col gap-10">
          <div className="md:mt-10 px-10 flex items-center justify-between">
            <div>
              <MagnifyingGlassIcon className="size-8" />
            </div>

            <div
              className="hidden md:block cursor-pointer"
              onClick={() => {
                const user = list.find((user: any) => user.username === username);
                const userId = user.id;
                navigate(`/accounts/${userId}`);
              }}
            >
              <DotsVerticalIcon className="size-7" />
            </div>

            <div
              className="text-white size-11 rounded-full grid place-items-center text-xl md:hidden uppercase cursor-pointer"
              style={{ backgroundColor: iconColor }}
              onClick={() => setShowAccountModal(true)}
            >
              {username && username[0]}
            </div>
          </div>

          <div className="h-full px-10">{children}</div>
        </div>
      </div>

      {showAccountModal && (
        <Modal showCloseIcon closeModal={() => setShowAccountModal(false)}>
          <div className="flex flex-col gap-6">
            {list.map((item: any) => {
              const color = item.icon_color ? `#${item.icon_color}` : 'black';
              const isActive = item.username === username;

              return (
                <div key={item.user_id} className="flex items-center gap-7 cursor-pointer">
                  <div
                    className="rounded-full size-8 text-white text-lg grid place-items-center"
                    style={{ backgroundColor: color }}
                  >
                    {item.name[0]}
                  </div>

                  <div
                    className={`text-sm ${isActive && 'font-bold'}`}
                    onClick={() => {
                      navigate(`/notes?username=${item.username}&relationshipType=${item.name_of_relation}`);
                      setShowAccountModal(false);
                    }}
                  >
                    {item.username}@notestofutureyou.com
                  </div>

                  {isActive && (
                    <DotsVerticalIcon
                      className="size-5"
                      onClick={() => {
                        const user = list.find((user: any) => user.username === username);
                        const userId = user.id;
                        navigate(`/accounts/${userId}`);
                      }}
                    />
                  )}
                </div>
              );
            })}

            <div className="flex items-center justify-between">
              <div className="rounded-full size-8 text-lg grid place-items-center">+</div>

              <div className="text-sm flex-grow ml-6">Add Kid</div>
            </div>

            <div className="flex items-center justify-between cursor-pointer" onClick={() => navigate('/my-account')}>
              <div className="rounded-full size-8 text-lg grid place-items-center">
                <AvatarIcon className="text-[#D9D9D9] size-7" />
              </div>

              <div className="text-sm flex-grow ml-6">My Account</div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
