import { useContext, useState } from 'react';
import { Button } from '../../components/button';
import { Input } from '../../components/input';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'src/context/AuthContext';
import AuthCheckHelper from 'src/helpers/AuthCheckHelper';
import { HamburgerMenuIcon } from '@radix-ui/react-icons';

export default function SignUp() {
  const navigate = useNavigate();

  const { signup } = useContext(AuthContext);

  const [email, setEmail] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // setIsSubmitting(true);
    // await signup(name, email, password);
    // setIsSubmitting(false);

    if (email.trim() !== '') {
      navigate(`/setup?fromSignup=true&email=${email}`);
    }
  };

  return (
    <AuthCheckHelper>
      <div className="py-11 px-3 md:px-6">
        <div className="flex md:hidden justify-end">
          <HamburgerMenuIcon className="size-7" />
        </div>

        <div className="hidden md:flex justify-end">
          <span className="cursor-pointer" onClick={() => navigate('/')}>
            Log In
          </span>
        </div>

        <div className="min-h-[90vh] flex flex-col items-center justify-center bg-white px-4">
          <div className="w-full max-w-3xl">
            <h1 className="font-semibold text-4xl text-neutral-900">Give your kid an unimaginable gift</h1>
            <p className="text-lg mt-2 font-medium text-neutral-100">
              Create their story through your eyes and share your love and wisdom
            </p>

            <form onSubmit={handleSubmit} className="flex gap-2 flex-col md:flex-row mt-10 md:gap-0">
              <Input
                type="email"
                placeholder="Your best email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="flex-grow font-light text-neutral-100 placeholder:text-neutral-100 border border-neutral-200 text-base rounded-r-none"
                required
              />

              <Button
                type="submit"
                className={`font-semibold rounded-r-lg rounded-l-none text-white text-xl min-w-[297px] ${isSubmitting ? 'bg-gray-600' : 'bg-primary-purple hover:bg-primary-purple/80'}`}
                disabled={isSubmitting}
              >
                Sign Up
              </Button>
            </form>

            <div className="mt-9 text-center block md:hidden">
              or{' '}
              <span className="underline cursor-pointer" onClick={() => navigate('/')}>
                login?
              </span>
            </div>
          </div>
        </div>
      </div>
    </AuthCheckHelper>
  );
}
