import { ArrowLeftIcon, TrashIcon } from '@radix-ui/react-icons';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/components/button';
import Layout from 'src/components/layout';
import Modal from 'src/components/modal';

export default function NotePage() {
  const navigate = useNavigate();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [email, setEmail] = useState('');
  const [relation, setRelation] = useState('');

  const handleDeleteNote = () => {
    setShowDeleteModal(false);
  };

  useEffect(() => {
    setTimeout(() => {
      const data = localStorage.getItem('email');
      const relation = localStorage.getItem('relation');
      setRelation(relation || '');
      setEmail(data || '');
    }, 300);
  }, []);

  return (
    <div className="h-full min-h-screen">
      <Layout>
        <>
          <div className="flex items-center justify-between">
            <ArrowLeftIcon className="size-6 cursor-pointer" onClick={() => navigate(-1)} />

            {/* <p className="font-semibold text-sm md:text-2xl">Edit</p> */}
          </div>

          <div className="mt-7 flex flex-col justify-between gap-16 min-h-full">
            <div className="flex flex-col gap-[30px]">
              <div className="flex items-center justify-between font-semibold">
                <h1 className="text-sm md:text-2xl">What’s going on today {relation}?</h1>

                {/* <p className="text-xs md:text-2xl">8:32 AM</p> */}
              </div>

              <div className="">
                <p
                  className="text-xl md:text-2xl"
                  dangerouslySetInnerHTML={{
                    __html: email.split('--')[0],
                  }}
                ></p>
              </div>
            </div>

            {/* <div className="flex justify-end ">
              <TrashIcon className="size-6 cursor-pointer" onClick={() => setShowDeleteModal(true)} />
            </div> */}
          </div>
        </>
      </Layout>

      {showDeleteModal && (
        <Modal>
          <h2 className="text-xl font-bold">Delete this note?</h2>

          <p className="mt-20">You can also edit the note by tapping it.</p>

          <div className="flex items-center justify-between gap-11 mt-12">
            <Button
              className="bg-primary-purple hover:bg-primary-purple/80 text-white rounded-3xl px-7 py-4"
              onClick={() => setShowDeleteModal(false)}
            >
              Back
            </Button>

            <Button className="border border-black/20 rounded-3xl px-7 py-4" onClick={handleDeleteNote}>
              Delete
            </Button>
          </div>

          <div className="mt-7 flex items-center gap-2 text-neutral-300 text-sm">
            <input type="checkbox" />
            <span className="">Don't show this again</span>
          </div>
        </Modal>
      )}
    </div>
  );
}
