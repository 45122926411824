import { ArrowLeftIcon } from '@radix-ui/react-icons';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createRelationsApiHandler } from 'src/api-handler/relations';
import { Button } from 'src/components/button';
import { Input } from 'src/components/input';
import { AuthContext } from 'src/context/AuthContext';
import { RelationshipContext } from 'src/context/RelationshipContext';

export default function PasswordSetup() {
  const navigate = useNavigate();
  const { relationship } = useContext(RelationshipContext);
  const { signup } = useContext(AuthContext);

  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState(relationship?.signupEmail);

  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    handleSignup();
  };

  const handleSignup = async () => {
    setLoading(true);

    const response: any = await signup(name, email || '', password);

    if (response?.user) {
      setTimeout(async () => {
        navigate('/choose-plan');
        const response = await createRelationsApiHandler({
          name_of_relation: relationship?.relationshipType || '',
          due_date: relationship?.dueDate,
          username: relationship?.username || '',
          name: relationship?.name || '',
          icon_color: Math.floor(Math.random() * 16777215)
            .toString(16)
            .padStart(6, '0'),
        });
        console.log('response -> ', response);
      }, 500);
    }

    setLoading(false);
  };

  return (
    <div className="px-6 py-11 flex flex-col items-center justify-between relative h-full">
      <ArrowLeftIcon className="size-6 absolute self-start md:hidden cursor-pointer" onClick={() => navigate(-1)} />

      <div className="pt-20 w-full max-w-xl flex flex-col md:justify-center flex-grow">
        <div className="flex flex-col items-center md:items-start gap-2">
          <h1 className="text-2xl md:text-4xl font-bold block md:hidden">Finish setup</h1>

          <h1 className="text-2xl md:text-4xl font-bold hidden md:block">
            Start telling <span className="capitalize">{relationship?.name}&apos;s</span> story
          </h1>

          <div className="w-[100px] h-0.5 bg-black/10 rounded-xl block md:hidden">
            <div className="bg-success h-full w-full rounded-xl"></div>
          </div>
        </div>

        <p className="text-xl mt-7 block md:hidden">
          This is your password to access your notes and account. If later you want to share the inbox you’ve created,
          you’d do that separately
        </p>

        <div className="mt-10 flex flex-col gap-2">
          <p className="hidden md:block">Just for you for now; later you can share what you want</p>
          <Input
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="rounded-3xl border border-neutral-300 text-neutral-300 placeholder:text-neutral-300"
            required
          />
          <Input
            placeholder="Enter your name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="rounded-3xl border border-neutral-300 text-neutral-300 placeholder:text-neutral-300"
            required
          />
          <Input
            type="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="rounded-3xl border border-neutral-300 text-neutral-300 placeholder:text-neutral-300"
            required
          />
        </div>
      </div>

      <div className="flex justify-center w-full md:hidden">
        <Button
          disabled={password.trim() === ''}
          className="py-4 px-12 w-full text-white text-xl rounded-3xl bg-primary-purple hover:bg-primary-purple/80 disabled:bg-primary-purple/50"
          onClick={handleSubmit}
        >
          Finish
        </Button>
      </div>

      <div className="hidden md:flex self-end">
        <Button
          disabled={password.trim() === '' || loading}
          className="py-4 px-12 text-white text-xl rounded-3xl bg-primary-purple hover:bg-primary-purple/80 disabled:bg-primary-purple/50"
          onClick={handleSubmit}
        >
          {loading ? 'Please Wait...' : 'Finish'}
        </Button>
      </div>
    </div>
  );
}
