import axios from 'axios';

export const getRelationsApiHandler = async () => {
  try {
    const response = await axios.get(`/api/relationships/`);
    return response.data;
  } catch (error: any) {
    return { error: error?.response?.data?.message || 'Something went wrong' };
  }
};

export const getSingleRelationApiHandler = async (id: string) => {
  try {
    const response = await axios.get(`/api/relationships/${id}`);
    return response.data;
  } catch (error: any) {
    return { error: error?.response?.data?.message || 'Something went wrong' };
  }
};

interface ICreateRelationsRequest {
  name_of_relation: string;
  due_date: Date;
  name: string;
  username: string;
  icon_color: string;
}

export const createRelationsApiHandler = async (body: ICreateRelationsRequest) => {
  try {
    const response = await axios.post(`/api/relationships/`, body);
    return response.data;
  } catch (error: any) {
    return { error: error?.response?.data?.message || 'Something went wrong' };
  }
};

export const updateRelationshipsApiHandler = async (id: string, data: any) => {
  try {
    const token = localStorage.getItem('accessToken');
    const response = await axios.put(`/api/relationships/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    console.log(response.data);
    return response.data;
  } catch (error: any) {
    return { error: error?.response?.data?.message || 'Something went wrong' };
  }
};
