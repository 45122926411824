import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'src/context/AuthContext';

interface AuthCheckHelperProps {
  children: React.ReactNode;
  auth?: boolean;
}

export default function AuthCheckHelper(props: AuthCheckHelperProps) {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (props.auth && !user) {
      navigate('/');
    }
    if (!props.auth && user) {
      navigate('/dashboard');
    }
  }, [user]);

  return <>{props.children}</>;
}
