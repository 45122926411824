import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getEmailsApiHandler } from 'src/api-handler/mail';
import Layout from 'src/components/layout';
import { AuthContext } from 'src/context/AuthContext';
import { RelationshipContext } from 'src/context/RelationshipContext';
import AuthCheckHelper from 'src/helpers/AuthCheckHelper';

export default function Notes() {
  const navigate = useNavigate();
  const { relationship } = useContext(RelationshipContext);
  const { user } = useContext(AuthContext);

  let [searchParams] = useSearchParams();
  const username = searchParams.get('username');
  const relationshipType = searchParams.get('relationshipType');

  const [emails, setEmails] = useState([]);
  const [loading, setLoading] = useState(true);

  const getEmails = async (username: string) => {
    try {
      setLoading(true);
      const response = await getEmailsApiHandler(username);
      if (response?.length) {
        setEmails(response);
      } else {
        setEmails([]);
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (username) getEmails(username);
  }, [username]);

  useEffect(() => {
    if (!user?.subscription) {
      navigate('/choose-plan');
    }
  }, [user]);

  console.log('relationship -> ', relationship);

  return (
    <AuthCheckHelper auth>
      <div className="h-full min-h-screen">
        <Layout>
          <div className="">
            {loading ? (
              <p>Fetching emails...</p>
            ) : emails.length ? (
              <ul className="flex flex-col gap-[30px]">
                {emails.map((email: any, index: number) => (
                  <li key={index} className="flex items-start gap-3 md:gap-11">
                    {/* <div className="size-5 border border-neutral-300 " /> */}

                    <div
                      className="flex flex-col gap-0.5 w-full cursor-pointer"
                      onClick={() => {
                        console.log('email -> ', email);
                        localStorage.setItem('email', email);
                        localStorage.setItem('relation', relationshipType as string);

                        navigate('/notes/email');
                      }}
                    >
                      <div className="flex justify-between items-start md:gap-11">
                        <h2 className="text-sm md:text-lg font-semibold">What's going on today {relationshipType}?</h2>

                        <div className="flex items-center justify-between md:flex-grow">
                          <div
                            className="pb-4"
                            // style to render only one line and then add ... at the end
                            style={{
                              display: '-webkit-box',
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: 'vertical',
                              height: '1.5em',
                              overflow: 'hidden',
                            }}
                            dangerouslySetInnerHTML={{
                              __html: email.split('--')[0],
                            }}
                          />
                          <p className="text-xs md:text-base ">8:32 AM</p>
                        </div>
                      </div>

                      <p className="text-xs line-clamp-1 md:hidden">
                        Good morning sweetheart today I’m yada yada yada and then more yayayas and th3en
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <div className="w-full max-w-[712px] md:text-2xl">
                <p>
                  Thank you {relationship?.relationshipType}!
                  <br />
                  <br />
                  The first prompt has been sent to your inbox. Responses to those prompts will show up here and you can
                  edit or delete.
                  <br />
                  <br />
                  And after you’ve done some writing, you can export it to book format, share the inbox with me, or even
                  create a Shakespearean screenplay about my life using AI!
                </p>
              </div>
            )}
          </div>
        </Layout>
      </div>
    </AuthCheckHelper>
  );
}
