import { ArrowLeftIcon, CheckIcon, ChevronRightIcon } from '@radix-ui/react-icons';
import { hexToHsva, hsvaToHex } from '@uiw/color-convert';
import ShadeSlider from '@uiw/react-color-shade-slider';
import Wheel from '@uiw/react-color-wheel';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Button } from 'src/components/button';
import { Input } from 'src/components/input';
import Modal from 'src/components/modal';
import TimeRoller from 'react-timeroller';
import { useNavigate, useParams } from 'react-router-dom';
import { getSingleRelationApiHandler, updateRelationshipsApiHandler } from 'src/api-handler/relations';
import AuthCheckHelper from 'src/helpers/AuthCheckHelper';

const HOUR_RANGE = Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, '0'));
const MINUTE_RANGE = Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, '0'));
const MERIDIEM_RANGE = ['AM', 'PM'];
const DAY_RANGE = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'];

export default function AccountDetails() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [relation, setRelation] = useState<any>({});

  const fetchAccountDetails = async (id: string, disableRefresh: boolean = false) => {
    if (!disableRefresh) setLoading(true);

    try {
      const { relationship } = await getSingleRelationApiHandler(id);
      setRelation(relationship);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) fetchAccountDetails(id);
  }, [id]);

  const [editName, setEditName] = useState(false);
  const [name, setName] = useState('');
  const [inputName, setInputName] = useState('');

  const [editBirthdate, setEditBirthdate] = useState(false);
  const [birthdate, setBirthdate] = useState<Date | null>(null);

  const [editRelation, setEditRelation] = useState(false);
  const [relationType, setRelationType] = useState('');

  const [editIconColor, setEditIconColor] = useState(false);
  const [iconColor, setIconColor] = useState('');
  const [iconColorInput, setIconColorInput] = useState(iconColor);

  const [editDailyPrompt, setEditDailyPrompt] = useState(false);
  const [dailyPromptHour, setDailyPromptHour] = useState('');
  const [dailyPromptMinute, setDailyPromptMinute] = useState('');
  const [dailyPromptMeridiem, setDailyPromptMeridiem] = useState('  ');
  const [dailyPromptTime, setDailyPromptTime] = useState('');

  const [editWeeklyPrompt, setEditWeeklyPrompt] = useState(false);
  const [weeklyPromptDay, setWeeklyPromptDay] = useState('Sun');
  const [weeklyPromptHour, setWeeklyPromptHour] = useState('08');
  const [weeklyPromptMinute, setWeeklyPromptMinute] = useState('00');
  const [weeklyPromptMeridiem, setWeeklyPromptMeridiem] = useState('PM');
  const [weeklyPromptTime, setWeeklyPromptTime] = useState(
    `${weeklyPromptDay} ${weeklyPromptHour}:${weeklyPromptMinute} ${weeklyPromptMeridiem}`
  );

  const [editReminders, setEditReminders] = useState(false);
  const [reminders, setReminders] = useState('On');

  const [showFeatureLockModal, setShowFeatureLockModal] = useState(false);

  function convertTo24HourFormat(timeStr: any) {
    // Parse the input time string
    const [time, period] = timeStr.split(' ');
    let [hours, minutes] = time.split(':');

    // Convert to integers
    hours = parseInt(hours, 10);
    minutes = parseInt(minutes, 10);

    // Adjust for AM/PM
    if (period.toUpperCase() === 'PM' && hours !== 12) {
      hours += 12;
    }
    if (period.toUpperCase() === 'AM' && hours === 12) {
      hours = 0;
    }

    // Format with leading zeros
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');

    // Return in HH:mm:ss format
    return `${formattedHours}:${formattedMinutes}:00`;
  }

  function convertTo12HrFormat(timeStr: string) {
    const [hours, minutes, seconds] = timeStr.split(':').map(Number);

    // Convert 24-hour to 12-hour format
    const hours12 = hours % 12 || 12;
    const meridian = hours < 12 ? 'AM' : 'PM';

    return {
      hours: hours12.toString().padStart(2, '0'),
      minutes: minutes.toString().padStart(2, '0'),
      meridian: meridian,
    };
  }

  useEffect(() => {
    if (relation) {
      setName(relation.name);
      setInputName(relation.name?.trim());
      setBirthdate(new Date(relation.due_date));
      setRelationType(relation.name_of_relation);
      setIconColorInput(relation.icon_color);
      setIconColor(relation.icon_color);
      const {
        hours: dailyHourValue,
        minutes: dailyMinutesValue,
        meridian: dailyMeridianValue,
      } = convertTo12HrFormat(relation.daily_prompts_time || '19:00:00');
      setDailyPromptHour(dailyHourValue);
      setDailyPromptMinute(dailyMinutesValue);
      setDailyPromptMeridiem(dailyMeridianValue);
      setDailyPromptTime(`${dailyPromptHour}:${dailyPromptMinute} ${dailyPromptMeridiem}`);
    }
  }, [relation]);

  const updateRelation = async (id: string, data: any) => {
    const relationship = { ...relation, ...data };

    try {
      await updateRelationshipsApiHandler(id, relationship);
      await fetchAccountDetails(id, false);
    } catch (error) {
      console.error(error);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <AuthCheckHelper auth>
      <div className="px-6 py-11 min-h-full relative">
        <ArrowLeftIcon className="size-6 absolute self-start cursor-pointer" onClick={() => navigate(-1)} />

        <h1 className="mt-20 text-center font-bold text-2xl md:text-4xl">{relation.username}@notestofutureyou.com</h1>

        <div className="mt-14 flex flex-col gap-7 max-w-md mx-auto">
          <div className="">
            <h2 className="font-bold text-xl">Settings</h2>

            <div className="ml-5 mt-3 flex flex-col gap-1 text-lg">
              <div className="flex items-center justify-between cursor-pointer" onClick={() => setEditName(true)}>
                <p className="">Name</p>
                <p className="font-light">{name}</p>
              </div>

              <div className="flex items-center justify-between cursor-pointer" onClick={() => setEditBirthdate(true)}>
                <p className="cursor-pointer">DOB</p>
                <p className="font-light">{birthdate?.toLocaleDateString('en-US')}</p>
              </div>

              <div className="flex items-center justify-between cursor-pointer" onClick={() => setEditRelation(true)}>
                <p className="cursor-pointer">Your relationship</p>
                <p className="font-light capitalize">{relationType}</p>
              </div>

              <div className="flex items-center justify-between cursor-pointer" onClick={() => setEditIconColor(true)}>
                <p className="cursor-pointer">Icon color</p>
                <p className="font-light">#{iconColor}</p>
              </div>
            </div>
          </div>

          <div className="">
            <h2 className="font-bold text-xl">Prompts</h2>
            <div className="ml-5 mt-3 flex flex-col gap-1 text-lg">
              <div
                className="flex items-center justify-between cursor-pointer"
                onClick={() => setEditDailyPrompt(true)}
              >
                <p className="cursor-pointer">Daily</p>
                <p className="font-light">{dailyPromptTime}</p>
              </div>

              <div className="flex items-center justify-between cursor-pointer">
                <p
                // className="cursor-pointer"
                // onClick={() => setEditWeeklyPrompt(true)}
                >
                  Weekly
                </p>
                <p className="font-light">{weeklyPromptTime}</p>
              </div>

              <div className="flex items-center justify-between cursor-pointer">
                <p className="cursor-pointer" onClick={() => setEditReminders(true)}>
                  Memory lane reminders
                </p>
                <p className="font-light">{reminders}</p>
              </div>
            </div>
          </div>

          <div className="">
            <h2 className="font-bold text-xl">Export or Share</h2>

            <div className="ml-5 mt-3 flex flex-col gap-1 text-lg">
              <div className="flex items-center justify-between cursor-pointer">
                <p className="">AI create</p>
                <p className="font-light">
                  <ChevronRightIcon className="size-6" />
                </p>
              </div>

              <div
                className="flex items-center justify-between cursor-pointer"
                onClick={() => setShowFeatureLockModal(true)}
              >
                <p className="cursor-pointer">Auto forward</p>
                <p className="font-light">Off</p>
              </div>

              <div
                className="flex items-center justify-between cursor-pointer"
                onClick={() => setShowFeatureLockModal(true)}
              >
                <p className="cursor-pointer">Share inbox</p>
                <p className="font-light">Off</p>
              </div>
            </div>
          </div>
        </div>

        {editName && (
          <Modal showCloseIcon position="right" closeModal={() => setEditName(false)}>
            <h2 className="text-center font-bold text-2xl">Name</h2>

            <Input
              type="text"
              placeholder="Name"
              value={inputName}
              onChange={(e) => setInputName(e.target.value)}
              className="mt-10"
            />

            <p className="mt-5">Name can be upto 12 characters long. Spaces are allowed.</p>

            <div className="flex justify-center mt-40">
              <Button
                onClick={() => {
                  if (inputName.trim() !== '') id && updateRelation(id, { name: inputName.trim() });
                  setEditName(false);
                }}
                className="rounded-3xl py-4 px-9 text-white bg-primary-purple hover:bg-primary-purple/80"
              >
                OK
              </Button>
            </div>
          </Modal>
        )}

        {editBirthdate && (
          <Modal showCloseIcon position="right" closeModal={() => setEditBirthdate(false)}>
            <h2 className="text-center font-bold text-2xl">DOB</h2>

            <div className="mt-10">
              <DatePicker
                placeholderText="MM/DD/YYYY"
                selected={birthdate}
                onChange={(val) => setBirthdate(val)}
                className="text-center text-black cursor-pointer border border-neutral-100/30 rounded-2xl py-3 w-full focus-within:outline-none"
              />
            </div>

            <div className="flex justify-center mt-40">
              <Button
                onClick={() => {
                  id && updateRelation(id, { due_date: birthdate });
                  setEditBirthdate(false);
                }}
                className="rounded-3xl py-4 px-9 text-white bg-primary-purple hover:bg-primary-purple/80"
              >
                OK
              </Button>
            </div>
          </Modal>
        )}

        {editRelation && (
          <Modal showCloseIcon position="right" closeModal={() => setEditRelation(false)}>
            <h2 className="text-center font-bold text-2xl">Relationship</h2>

            <p className="mt-5 text-center">Changing this will change your content</p>

            <div className="mt-10 flex flex-col gap-11">
              <div className="flex items-center justify-between">
                <span
                  className={`cursor-pointer ${relationType === 'mom' && 'font-bold'}`}
                  onClick={() => setRelationType('mom')}
                >
                  Mom
                </span>
                {relationType === 'mom' && <CheckIcon className="size-5 bg-success rounded-full text-white" />}
              </div>

              <div className="flex items-center justify-between">
                <span
                  className={`cursor-pointer ${relationType === 'dad' && 'font-bold'}`}
                  onClick={() => setRelationType('dad')}
                >
                  Dad
                </span>
                {relationType === 'dad' && <CheckIcon className="size-5 bg-success rounded-full text-white" />}
              </div>

              <div className="flex items-center justify-between">
                <span
                  className={`cursor-pointer ${relationType === 'relative' && 'font-bold'}`}
                  onClick={() => setRelationType('relative')}
                >
                  Relative
                </span>
                {relationType === 'relative' && <CheckIcon className="size-5 bg-success rounded-full text-white" />}
              </div>
            </div>

            <div className="flex justify-center mt-40">
              <Button
                onClick={() => {
                  id && updateRelation(id, { name_of_relation: relationType });
                  setEditRelation(false);
                }}
                className="rounded-3xl py-4 px-9 text-white bg-primary-purple hover:bg-primary-purple/80"
              >
                OK
              </Button>
            </div>
          </Modal>
        )}

        {editIconColor && (
          <Modal showCloseIcon position="right" closeModal={() => setEditIconColor(false)}>
            <h2 className="text-center font-bold text-2xl">Set icon color</h2>

            <div className="mt-10 flex flex-col items-center gap-2">
              <Wheel
                color={hexToHsva(`#${iconColorInput}`)}
                onChange={(val) => setIconColorInput(val.hex.slice(1).toUpperCase())}
              />

              <ShadeSlider
                hsva={hexToHsva(`#${iconColorInput}`)}
                style={{ width: 210, marginTop: 20 }}
                onChange={(newShade) => {
                  const hsvaString = hexToHsva(`#${iconColorInput}`);

                  const newColorHsva = { ...hsvaString, ...newShade };

                  const newColorHex = hsvaToHex(newColorHsva);

                  setIconColorInput(newColorHex.slice(1).toUpperCase());
                }}
              />
            </div>

            <div className="flex justify-center mt-20">
              <Button
                onClick={() => {
                  setIconColor(iconColorInput);
                  id && updateRelation(id, { icon_color: iconColorInput });
                  setEditIconColor(false);
                }}
                className="rounded-3xl py-4 px-9 text-white bg-primary-purple hover:bg-primary-purple/80"
              >
                Change
              </Button>
            </div>
          </Modal>
        )}

        {editDailyPrompt && (
          <Modal showCloseIcon position="right" closeModal={() => setEditDailyPrompt(false)}>
            <h2 className="text-center font-bold text-2xl">Daily Prompt</h2>

            <p className="mt-7 text-center">Set timezone in account setting</p>

            <TimePicker
              hour={dailyPromptHour}
              minute={dailyPromptMinute}
              meridiem={dailyPromptMeridiem}
              setHour={setDailyPromptHour}
              setMinute={setDailyPromptMinute}
              setMeridiem={setDailyPromptMeridiem}
            />

            <div className="flex justify-center mt-20">
              <Button
                onClick={() => {
                  const timeStr = `${dailyPromptHour}:${dailyPromptMinute} ${dailyPromptMeridiem}`;

                  setDailyPromptTime(timeStr);

                  const timeVal = convertTo24HourFormat(timeStr);

                  id && updateRelation(id, { daily_prompts_time: timeVal });

                  setEditDailyPrompt(false);
                }}
                className="rounded-3xl py-4 px-9 text-white bg-primary-purple hover:bg-primary-purple/80"
              >
                OK
              </Button>
            </div>
          </Modal>
        )}

        {editWeeklyPrompt && (
          <Modal showCloseIcon position="right" closeModal={() => setEditWeeklyPrompt(false)}>
            <h2 className="text-center font-bold text-2xl">Weekly Prompt</h2>

            <p className="mt-7 text-center">Set timezone in account setting</p>

            <TimePicker
              hour={weeklyPromptHour}
              minute={weeklyPromptMinute}
              meridiem={weeklyPromptMeridiem}
              setHour={setWeeklyPromptHour}
              setMinute={setWeeklyPromptMinute}
              setMeridiem={setWeeklyPromptMeridiem}
            />

            <div className="mt-3 flex items-center justify-around">
              {DAY_RANGE.map((day) => {
                const isSelected = day === weeklyPromptDay;
                return (
                  <p
                    key={day}
                    className={`cursor-pointer ${isSelected && 'font-bold'}`}
                    onClick={() => setWeeklyPromptDay(day)}
                  >
                    {day}
                  </p>
                );
              })}
            </div>

            <div className="flex justify-center mt-20">
              <Button
                onClick={() => {
                  setWeeklyPromptTime(
                    `${weeklyPromptDay} ${weeklyPromptHour}:${weeklyPromptMinute} ${weeklyPromptMeridiem}`
                  );
                  setEditWeeklyPrompt(false);
                }}
                className="rounded-3xl py-4 px-9 text-white bg-primary-purple hover:bg-primary-purple/80"
              >
                OK
              </Button>
            </div>
          </Modal>
        )}

        {editReminders && (
          <Modal showCloseIcon position="right" closeModal={() => setEditReminders(false)}>
            <h2 className="text-center font-bold text-2xl">Memory lane</h2>

            <p className="mt-7 text-center">Resurfaces your writing occasionally</p>

            <div className="mt-10 flex flex-col gap-11">
              <div className="flex items-center justify-between">
                <span
                  className={`cursor-pointer ${reminders === 'On' && 'font-bold'}`}
                  onClick={() => setReminders('On')}
                >
                  Enable
                </span>
                {reminders === 'On' && <CheckIcon className="size-5 bg-success rounded-full text-white" />}
              </div>

              <div className="flex items-center justify-between">
                <span
                  className={`cursor-pointer ${reminders === 'Off' && 'font-bold'}`}
                  onClick={() => setReminders('Off')}
                >
                  Disable
                </span>
                {reminders === 'Off' && <CheckIcon className="size-5 bg-success rounded-full text-white" />}
              </div>
            </div>

            <div className="flex justify-center mt-20">
              <Button
                onClick={() => setEditReminders(false)}
                className="rounded-3xl py-4 px-9 text-white bg-primary-purple hover:bg-primary-purple/80"
              >
                OK
              </Button>
            </div>
          </Modal>
        )}

        {showFeatureLockModal && (
          <Modal showCloseIcon position="right" closeModal={() => setShowFeatureLockModal(false)}>
            <h2 className="text-center font-bold text-2xl">50 notes to unlock!</h2>

            <p className="mt-20 text-center">
              Before you can use this feature you’ll need to create more notes. Sorry!
            </p>

            <div className="flex justify-center mt-40">
              <Button
                onClick={() => setShowFeatureLockModal(false)}
                className="rounded-3xl py-4 px-9 text-white bg-primary-purple hover:bg-primary-purple/80"
              >
                OK
              </Button>
            </div>
          </Modal>
        )}
      </div>
    </AuthCheckHelper>
  );
}

const TimePicker = ({
  hour,
  minute,
  meridiem,
  setHour,
  setMinute,
  setMeridiem,
}: {
  hour: string;
  minute: string;
  meridiem: string;
  setHour: Dispatch<SetStateAction<string>>;
  setMinute: Dispatch<SetStateAction<string>>;
  setMeridiem: Dispatch<SetStateAction<string>>;
}) => {
  return (
    <div className="mt-10 mx-auto flex items-center justify-center gap-3">
      <TimeRoller range={HOUR_RANGE} selectedValue={HOUR_RANGE[HOUR_RANGE.indexOf(hour)]} setSelectedValue={setHour} />

      <p className="font-bold text-xl">:</p>

      <TimeRoller
        range={MINUTE_RANGE}
        selectedValue={MINUTE_RANGE[MINUTE_RANGE.indexOf(minute)]}
        setSelectedValue={setMinute}
      />

      <TimeRoller
        range={MERIDIEM_RANGE}
        selectedValue={MERIDIEM_RANGE[MERIDIEM_RANGE.indexOf(meridiem)]}
        setSelectedValue={setMeridiem}
      />
    </div>
  );
};
