// create a auth context where we can store the user and we manage login, logout, and signup functions

import axios from 'axios';
import { createContext, useEffect, useState } from 'react';
import { getCurrentUserApiHandler, loginApiHandler, signApiHandler } from 'src/api-handler/auth';

type User = {
  email: string;
  password: string;
  subscription?: any;
};

type AuthContextType = {
  user: User | null;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  signup: (name: string, email: string, password: string) => Promise<void>;
  fetchUser: () => Promise<void>;
};

export const AuthContext = createContext<AuthContextType>({
  user: null,
  login: async () => {},
  signup: async () => {},
  logout: () => {},
  fetchUser: async () => {},
});

export const AuthProvider = ({ children }: any) => {
  const [authLoading, setAuthLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null);

  const login = async (email: string, password: string) => {
    const user = await loginApiHandler({ email, password });

    if (user.error) {
      alert(user.error);
      return;
    }

    setUser(user.user);

    localStorage.setItem('accessToken', user.token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('accessToken');
    axios.defaults.headers.common['Authorization'] = '';
  };

  const signup = async (name: string, email: string, password: string) => {
    const user = await signApiHandler({ name, email, password });
    if (user.error) {
      alert(user.error);
      return;
    }
    setUser(user.user);
    localStorage.setItem('accessToken', user.token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
    return user;
  };

  const fetchUser = async () => {
    try {
      const user = await getCurrentUserApiHandler();
      setUser(user);
    } catch (error) {
      console.log('error -> ', error);
    } finally {
      setAuthLoading(false);
    }
  };

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    console.log('accessToken -> ', accessToken);

    if (accessToken) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
      fetchUser();
    } else {
      setAuthLoading(false);
    }
  }, []);

  if (authLoading) {
    return <div>Loading...</div>;
  }

  return <AuthContext.Provider value={{ user, login, logout, signup, fetchUser }}>{children}</AuthContext.Provider>;
};
