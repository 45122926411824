import { ArrowLeftIcon, CheckIcon } from '@radix-ui/react-icons';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCurrentUserApiHandler, updateCurrentUserApiHandler } from 'src/api-handler/auth';
import { Button } from 'src/components/button';
import { Input } from 'src/components/input';
import Modal from 'src/components/modal';
import { AuthContext } from 'src/context/AuthContext';
import AuthCheckHelper from 'src/helpers/AuthCheckHelper';

export default function MyAccount() {
  const navigate = useNavigate();

  const { logout } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState<any>();

  const [editName, setEditName] = useState(false);
  const [name, setName] = useState('');
  const [inputName, setInputName] = useState('');

  const [editTimezone, setEditTimezone] = useState(false);
  const [timezone, setTimezone] = useState('');

  const [editEmailAddress, setEditEmailAddress] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [inputEmail, setInputEmail] = useState('');

  const [editPassword, setEditPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const [editMembership, setEditMembership] = useState(false);
  const [membershipPlan, setMembershipPlan] = useState('');

  const fetchCurrentUserDetails = async () => {
    setIsLoading(true);

    try {
      const response = await getCurrentUserApiHandler();
      setUserDetails(response);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCurrentUserDetails();
  }, []);

  useEffect(() => {
    if (userDetails) {
      setName(userDetails.name);
      setInputName(userDetails.name);
      setEmailAddress(userDetails.email);
      setInputEmail(userDetails.email);
      setMembershipPlan(userDetails.subscriptionType); //TODO

      console.log({ userDetails });
    }
  }, [userDetails]);

  const updateUser = async (data: any) => {
    try {
      await updateCurrentUserApiHandler(data);
      await fetchCurrentUserDetails();
    } catch (error) {}
  };

  if (isLoading) return <p>Loading...</p>;

  return (
    <AuthCheckHelper auth>
      <div className="px-6 py-11 min-h-full relative">
        <ArrowLeftIcon className="size-6 absolute self-start cursor-pointer" onClick={() => navigate(-1)} />

        <h1 className="mt-20 text-center font-bold text-2xl md:text-4xl">Account Settings</h1>

        <div className="mx-auto mt-20 flex flex-col gap-6 max-w-md text-xl">
          <div className="flex cursor-pointer items-center justify-between" onClick={() => setEditName(true)}>
            <p>My name</p>
            <p className="font-light">{name}</p>
          </div>

          {/* <div className="flex items-center justify-between">
            <p
            // className="cursor-pointer" onClick={() => setEditTimezone(true)}
            >
              Timezone
            </p>
            <p className="font-light">{timezone}</p>
          </div> */}

          <div className="flex items-center justify-between cursor-pointer">
            <p
            // onClick={() => setEditEmailAddress(true)}
            >
              Email Address
            </p>
            <p className="font-light">{emailAddress}</p>
          </div>

          <div className="flex items-center justify-between cursor-pointer" onClick={() => setEditPassword(true)}>
            <p>Password Reset</p>
            <p className="font-light"></p>
          </div>

          <div className="flex items-center justify-between cursor-pointer" onClick={() => setEditMembership(true)}>
            <p>Membership</p>
            <p className="font-light">{membershipPlan}</p>
          </div>
          <div className="flex text-red-500 items-center justify-between cursor-pointer" onClick={() => logout()}>
            <p>Log Out</p>
          </div>
        </div>

        {editName && (
          <Modal showCloseIcon position="right" closeModal={() => setEditName(false)}>
            <h2 className="text-center font-bold text-2xl">Name</h2>

            <Input
              type="text"
              placeholder="Name"
              value={inputName}
              onChange={(e) => setInputName(e.target.value)}
              className="mt-10"
            />

            <p className="mt-5">Name can be upto 12 characters long. Spaces are allowed.</p>

            <div className="flex justify-center mt-40">
              <Button
                onClick={() => {
                  if (inputName.trim() !== '') setName(inputName);
                  updateUser({ name: inputName });
                  setEditName(false);
                }}
                className="rounded-3xl py-4 px-9 text-white bg-primary-purple hover:bg-primary-purple/80"
              >
                OK
              </Button>
            </div>
          </Modal>
        )}

        {editTimezone && (
          <Modal showCloseIcon position="right" closeModal={() => setEditTimezone(false)}>
            <h2 className="text-center font-bold text-2xl">Timezone</h2>

            <div className="mt-10 flex flex-col gap-11">
              <div className="flex items-center justify-between">
                <span
                  className={`cursor-pointer ${timezone === 'EST' && 'font-bold'}`}
                  onClick={() => setTimezone('EST')}
                >
                  EST
                </span>
                {timezone === 'EST' && <CheckIcon className="size-5 bg-success rounded-full text-white" />}
              </div>

              <div className="flex items-center justify-between">
                <span
                  className={`cursor-pointer ${timezone === 'CST' && 'font-bold'}`}
                  onClick={() => setTimezone('CST')}
                >
                  CST
                </span>
                {timezone === 'CST' && <CheckIcon className="size-5 bg-success rounded-full text-white" />}
              </div>

              <div className="flex items-center justify-between">
                <span
                  className={`cursor-pointer ${timezone === 'MST' && 'font-bold'}`}
                  onClick={() => setTimezone('MST')}
                >
                  MST
                </span>
                {timezone === 'MST' && <CheckIcon className="size-5 bg-success rounded-full text-white" />}
              </div>

              <div className="flex items-center justify-between">
                <span
                  className={`cursor-pointer ${timezone === 'PST' && 'font-bold'}`}
                  onClick={() => setTimezone('PST')}
                >
                  PST
                </span>
                {timezone === 'PST' && <CheckIcon className="size-5 bg-success rounded-full text-white" />}
              </div>
            </div>

            <div className="flex justify-center mt-40">
              <Button
                onClick={() => setEditTimezone(false)}
                className="rounded-3xl py-4 px-9 text-white bg-primary-purple hover:bg-primary-purple/80"
              >
                OK
              </Button>
            </div>
          </Modal>
        )}

        {editEmailAddress && (
          <Modal showCloseIcon position="right" closeModal={() => setEditEmailAddress(false)}>
            <h2 className="text-center font-bold text-2xl">Email Address</h2>

            <Input
              type="email"
              placeholder="Email address"
              value={inputEmail}
              onChange={(e) => setInputEmail(e.target.value)}
              className="mt-10"
            />

            <div className="flex justify-center mt-40">
              <Button
                onClick={() => {
                  if (inputEmail.trim() !== '') setEmailAddress(inputEmail);
                  updateUser({ email: inputEmail.trim() });
                  setEditEmailAddress(false);
                }}
                className="rounded-3xl py-4 px-9 text-white bg-primary-purple hover:bg-primary-purple/80"
              >
                OK
              </Button>
            </div>
          </Modal>
        )}

        {editPassword && (
          <Modal showCloseIcon position="right" closeModal={() => setEditPassword(false)}>
            <h2 className="text-center font-bold text-2xl">Password Reset</h2>

            <div className="mt-10">
              <Input
                type="password"
                placeholder="Current Password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />

              <Input
                type="password"
                placeholder="New Password"
                className="mt-5"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>

            <div className="flex justify-center mt-40">
              <Button
                onClick={async () => {
                  await updateUser({ currentPassword, newPassword });
                  setCurrentPassword('');
                  setNewPassword('');
                  setEditPassword(false);
                }}
                className="rounded-3xl py-4 px-9 text-white bg-primary-purple hover:bg-primary-purple/80"
              >
                OK
              </Button>
            </div>
          </Modal>
        )}

        {editMembership && (
          <Modal showCloseIcon position="right" closeModal={() => setEditMembership(false)}>
            <h2 className="text-center font-bold text-2xl">Choose your plan</h2>

            <ul className="mt-10 flex flex-col gap-1">
              <li className="flex items-center gap-3">
                <CheckIcon className="size-4 text-white bg-success rounded-full" />
                <span className="">Add other kids</span>
              </li>

              <li className="flex items-center gap-3">
                <CheckIcon className="size-4 text-white bg-success rounded-full" />
                <span className="">1,000+ prompts</span>
              </li>

              <li className="flex items-center gap-3">
                <CheckIcon className="size-4 text-white bg-success rounded-full" />
                <span className="">Customized topics</span>
              </li>

              <li className="flex items-center gap-3">
                <CheckIcon className="size-4 text-white bg-success rounded-full" />
                <span className="">Export to book format</span>
              </li>

              <li className="flex items-center gap-3">
                <CheckIcon className="size-4 text-white bg-success rounded-full" />
                <span className="">Share notes and inbox</span>
              </li>

              <li className="flex items-center gap-3">
                <CheckIcon className="size-4 text-white bg-success rounded-full" />
                <span className="">Create a novel, play, etc. with AI</span>
              </li>

              <li className="flex items-center gap-3">
                <CheckIcon className="size-4 text-white bg-success rounded-full" />
                <span className="">
                  AI search for ultra control <span className="text-sm">(coming soon)</span>
                </span>
              </li>

              <li className="flex items-center gap-3">
                <CheckIcon className="size-4 text-white bg-success rounded-full" />
                <span className="">Cancel anytime</span>
              </li>
            </ul>

            <div className="flex flex-col items-center justify-between gap-4 mt-20">
              <div className="flex items-center justify-between gap-2">
                <Button
                  className={`py-5 px-7 rounded-xl border ${membershipPlan === '$2.99/mn' ? 'border-blue-accent' : 'border-black/10'}`}
                  onClick={() => setMembershipPlan('$2.99/mn')}
                >
                  $2.99/mn
                </Button>

                <Button
                  className={`py-5 px-7 rounded-xl border ${membershipPlan === '$29.99/yr' ? 'border-blue-accent' : 'border-black/10'}`}
                  onClick={() => setMembershipPlan('$29.99/yr')}
                >
                  $29.99/yr
                </Button>
              </div>

              <Button
                onClick={() => setEditMembership(false)}
                className="rounded-3xl py-4 px-9 text-white bg-primary-purple hover:bg-primary-purple/80"
              >
                OK
              </Button>
            </div>
          </Modal>
        )}
      </div>
    </AuthCheckHelper>
  );
}
