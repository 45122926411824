import axios from 'axios';

export const createSubscriptions = async (data: any) => {
  try {
    const response = await axios.post(`/subscription/create`, data);
    return response.data;
  } catch (error: any) {
    return { error: error?.response?.data?.message || 'Something went wrong' };
  }
};

export const confirmSubscription = async (data: any) => {
  try {
    const response = await axios.post(`/subscription/confirm-subscription`, data);
    return response.data;
  } catch (error: any) {
    return { error: error?.response?.data?.message || 'Something went wrong' };
  }
};
