// import AuthCheckHelper from 'src/helpers/AuthCheckHelper';
import { Button } from '../../components/button';
import Layout from 'src/components/layout';
import { useContext } from 'react';
import { RelationshipContext } from 'src/context/RelationshipContext';
import { useNavigate } from 'react-router-dom';
import AuthCheckHelper from 'src/helpers/AuthCheckHelper';

export default function Success() {
  const navigate = useNavigate();
  const { relationship, updateRelationship } = useContext(RelationshipContext);

  const handleButton = () => {
    navigate('/dashboard');
    // updateRelationship(null);
  };

  return (
    <AuthCheckHelper auth>
      <div className="px-6 py-11 h-full min-h-screen">
        <Layout>
          <div className="flex flex-col md:items-center justify-between h-full w-full">
            <div className="w-full max-w-[712px] md:text-2xl">
              <p>
                Thank you {relationship?.relationshipType}!
                <br />
                <br />
                The first prompt has been sent to your inbox. Responses to those prompts will show up here and you can
                edit or delete.
                <br />
                <br />
                And after you’ve done some writing, you can export it to book format, share the inbox with me, or even
                create a Shakespearean screenplay about my life using AI!
              </p>
            </div>

            <div className="flex justify-center gap-7">
              <Button
                className="text-white bg-primary-purple hover:bg-primary-purple/80 rounded-3xl h-14 w-40 md:hidden"
                onClick={() => navigate('/setup')}
              >
                Add another kid
              </Button>

              <Button className="border border-black rounded-3xl h-14 w-40" onClick={handleButton}>
                Got it!
              </Button>
            </div>
          </div>
        </Layout>
      </div>
    </AuthCheckHelper>
  );
}
