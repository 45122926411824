import { Dispatch, useContext, useEffect, useState } from 'react';
import { Button } from '../../components/button';
import { Input } from '../../components/input';
import AuthCheckHelper from 'src/helpers/AuthCheckHelper';
import { RelationshipContext } from 'src/context/RelationshipContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Modal from 'src/components/modal';

export default function Setup() {
  const navigate = useNavigate();
  const { updateRelationship } = useContext(RelationshipContext);
  const [name, setName] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showText, setShowText] = useState(false);

  let [searchParams] = useSearchParams();
  const fromSignup = searchParams.get('fromSignup');
  const email = searchParams.get('email') as string;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    console.log('Submitted name:', name);
    updateRelationship({
      name,
      fromSignup: fromSignup === 'true',
      signupEmail: email,
    });

    navigate('/email-setup');
  };

  useEffect(() => {
    updateRelationship(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthCheckHelper>
      <div className="px-6 py-11 flex flex-col items-center justify-between relative h-full">
        <div className="pt-20 w-full max-w-xl flex flex-col md:justify-center flex-grow">
          <div className="flex flex-col items-center md:items-start gap-2">
            <h1 className="text-2xl md:text-4xl text-black font-bold">Start your kid&apos;s story</h1>

            <div className="w-[100px] h-0.5 bg-black/10 rounded-xl block md:hidden">
              <div className="bg-success h-full w-1/5 rounded-xl"></div>
            </div>
          </div>

          <div className="mt-10 flex flex-col gap-2">
            <p className="hidden md:block">Who are you going to write for?</p>

            <Input
              type="text"
              placeholder="Enter your kid's first name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="rounded-3xl border border-neutral-300 text-neutral-300 placeholder:text-neutral-300"
              required
            />
          </div>

          <p className="mt-6 text-xs self-start cursor-pointer block md:hidden" onClick={() => setIsModalOpen(true)}>
            I have more than one kid
          </p>
        </div>

        <div className="flex justify-center w-full md:hidden">
          <Button
            className="w-full py-4 px-12 text-white text-xl rounded-3xl bg-primary-purple hover:bg-primary-purple/80 disabled:bg-primary-purple/50"
            disabled={name.trim() === ''}
            onClick={handleSubmit}
          >
            Looks Good
          </Button>
        </div>

        <div className="hidden md:flex items-center self-end gap-10">
          <p className="text-xs cursor-pointer" onClick={() => setShowText((prev) => !prev)}>
            {showText
              ? "Great! You can set up the other kids after this first one. Don't want to play favorites? Start with the youngest!"
              : 'I have more than one kid'}
          </p>

          <Button
            className="py-4 px-12 text-white text-xl rounded-3xl bg-primary-purple hover:bg-primary-purple/80 disabled:bg-primary-purple/50"
            disabled={name.trim() === ''}
            onClick={handleSubmit}
          >
            Step 1 of {fromSignup ? '5' : '4'}
          </Button>
        </div>

        {isModalOpen && (
          <Modal>
            <h2 className="text-xl pt-14 font-bold">I have multiple kids</h2>

            <p className="mt-20">
              Great!
              <br />
              <br />
              You can set up your other kids after you set up the first. Don&apos;t want to play favorites? Set up the
              youngest first.
            </p>

            <Button
              className="flex justify-center mt-12 rounded-full py-3 px-12 bg-primary-purple hover:bg-primary-purple/80 text-white mx-auto"
              onClick={() => setIsModalOpen(false)}
            >
              Got it
            </Button>
          </Modal>
        )}
      </div>
    </AuthCheckHelper>
  );
}
