import { useContext, useEffect, useState } from 'react';
import { Button } from '../../components/button';
import { Input } from '../../components/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../components/select';
import AuthCheckHelper from 'src/helpers/AuthCheckHelper';
import { RelationshipContext } from 'src/context/RelationshipContext';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import { createRelationsApiHandler } from 'src/api-handler/relations';
import { ArrowLeftIcon, CheckIcon } from '@radix-ui/react-icons';

export default function RelationSetup() {
  const navigate = useNavigate();
  const { relationship, updateRelationship } = useContext(RelationshipContext);
  const [relationshipType, setRelationshipType] = useState('');
  const [dueDate, setDueDate] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    updateRelationship({ ...relationship, relationshipType });
    setIsLoading(false);
    navigate('/birthdate-setup');
  };

  useEffect(() => {
    if (!relationship?.email) {
      navigate('/setup');
    }
  }, []);

  const buttonDisabled = !relationshipType || !dueDate || isLoading;

  return (
    // <AuthCheckHelper auth>
    <div className="px-6 py-11 flex flex-col items-center justify-between relative h-full">
      <ArrowLeftIcon className="size-6 absolute self-start md:hidden cursor-pointer" onClick={() => navigate(-1)} />

      <div className="pt-20 w-full max-w-xl flex flex-col justify-between md:justify-center flex-grow">
        <div className="">
          <div className="flex flex-col items-center md:items-start gap-2">
            <p className="hidden md:block -ml-8">You’ll get more relevant prompts from this info</p>

            <h1 className="text-2xl md:text-4xl text-black font-bold md:mt-5">
              Start <span className="capitalize">{relationship?.name}&apos;s</span> story
            </h1>

            <div className="w-[100px] h-0.5 bg-black/10 rounded-xl block md:hidden">
              <div className="bg-success h-full w-3/5 rounded-xl"></div>
            </div>
          </div>

          <p className="text-xl mt-7 block md:hidden">
            What is your relationship?
            <br />
            <br />
            You’ll receive more relevant daily prompts
          </p>

          <div className="mt-5 hidden md:flex flex-col gap-5">
            <div className="">
              <p className="">
                You are <span className="capitalize">{relationship?.name}&apos;s</span>:
              </p>

              <div className="flex items-center gap-9 mt-2">
                <div
                  className={`py-4 px-8 rounded-3xl cursor-pointer ${relationshipType === 'mom' ? 'border-[3px] border-primary-purple text-primary-purple' : 'border border-neutral-300 text-neutral-300'}`}
                  onClick={() => setRelationshipType('mom')}
                >
                  Mom
                </div>

                <div
                  className={`py-4 px-8 rounded-3xl cursor-pointer ${relationshipType === 'dad' ? 'border-[3px] border-primary-purple text-primary-purple' : 'border border-neutral-300 text-neutral-300'}`}
                  onClick={() => setRelationshipType('dad')}
                >
                  Dad
                </div>

                <div
                  className={`py-4 px-8 rounded-3xl cursor-pointer ${relationshipType === 'relative' ? 'border-[3px] border-primary-purple text-primary-purple' : 'border border-neutral-300 text-neutral-300'}`}
                  onClick={() => setRelationshipType('relative')}
                >
                  Relative
                </div>
              </div>
            </div>

            <p className="">You’ll get more relevant prompts from this info</p>
          </div>
        </div>

        <div className="flex flex-col gap-8 md:hidden">
          <div className="flex items-center justify-between" onClick={() => setRelationshipType('mom')}>
            Mom
            {relationshipType === 'mom' && (
              <span>
                <CheckIcon className="size-6 text-white bg-black rounded-full" />
              </span>
            )}
          </div>

          <div className="flex items-center justify-between" onClick={() => setRelationshipType('dad')}>
            Dad
            {relationshipType === 'dad' && (
              <span>
                <CheckIcon className="size-6 text-white bg-black rounded-full" />
              </span>
            )}
          </div>

          <div className="flex items-center justify-between" onClick={() => setRelationshipType('relative')}>
            Relative
            {relationshipType === 'relative' && (
              <span>
                <CheckIcon className="size-6 text-white bg-black rounded-full" />
              </span>
            )}
          </div>
        </div>

        <div className="flex justify-center w-full md:hidden">
          <Button
            disabled={relationshipType === ''}
            className="py-4 px-12 w-full text-white text-xl rounded-3xl bg-primary-purple hover:bg-primary-purple/80 disabled:bg-primary-purple/50"
            onClick={handleSubmit}
          >
            Continue
          </Button>
        </div>
      </div>

      <div className="hidden md:flex self-end">
        <Button
          disabled={relationshipType === ''}
          className="py-4 px-12 text-white text-xl rounded-3xl bg-primary-purple hover:bg-primary-purple/80 disabled:bg-primary-purple/50"
          onClick={handleSubmit}
        >
          Step 3 of {relationship?.fromSignup ? '5' : '4'}
        </Button>
      </div>
    </div>
    // </AuthCheckHelper>
  );
}
