import EmailSetup from './pages/EmailSetup';
import RelationSetup from './pages/RelationSetup';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import Setup from './pages/Setup';
import Success from './pages/Success';
import Notes from './pages/Notes';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import React from 'react';
import { AuthProvider } from './context/AuthContext';
import axios from 'axios';
import { RelationshipProvider } from './context/RelationshipContext';
import 'react-datepicker/dist/react-datepicker.css';
import ForgotPassword from './pages/ForgotPassword';
import BirthdateSetup from './pages/Birthdate-Setup';
import PasswordSetup from './pages/PasswordSetup';
import ChoosePlan from './pages/ChoosePlan';
import NotePage from './pages/NotePage';
import MyAccount from './pages/MyAccount';
import AccountDetails from './pages/Account';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(
  'pk_test_51QF2xvJWB8qqD2gGKgPFg7AVxlp8HWsT188FmsmFMgW6xSq4Rg4wPldOujHnCiYpfmYEP16tPzVUiDNc6dPd47nB00nyE6vwCK'
);

axios.defaults.baseURL = `https://api.notestofutureyou.com`;
// axios.defaults.baseURL = `http://localhost:3001`;

const router = createBrowserRouter([
  {
    path: '/',
    element: <Login />,
  },
  {
    path: '/signup',
    element: <SignUp />,
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
  },
  {
    path: '/setup',
    element: <Setup />,
  },
  {
    path: '/email-setup',
    element: <EmailSetup />,
  },
  {
    path: '/relation-setup',
    element: <RelationSetup />,
  },
  {
    path: '/birthdate-setup',
    element: <BirthdateSetup />,
  },
  {
    path: '/password-setup',
    element: <PasswordSetup />,
  },
  {
    path: '/choose-plan',
    element: <ChoosePlan />,
  },
  {
    path: '/success',
    element: <Success />,
  },
  {
    path: '/notes',
    element: <Notes />,
  },
  {
    path: '/notes/:id',
    element: <NotePage />,
  },
  {
    path: '/my-account',
    element: <MyAccount />,
  },
  {
    path: '/accounts/:id',
    element: <AccountDetails />,
  },
  {
    path: '/dashboard',
    element: <Dashboard />,
  },
]);

function App() {
  return (
    <AuthProvider>
      <RelationshipProvider>
        <React.StrictMode>
          <Elements
            stripe={stripePromise}
            options={{
              amount: 1000,
              mode: 'subscription',
              currency: 'usd',
            }}
          >
            <RouterProvider router={router} />
          </Elements>
        </React.StrictMode>
      </RelationshipProvider>
    </AuthProvider>
  );
}

export default App;
