import { ArrowLeftIcon } from '@radix-ui/react-icons';
import { useContext, useState } from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import { createRelationsApiHandler } from 'src/api-handler/relations';
import { Button } from 'src/components/button';
import { Input } from 'src/components/input';
import { RelationshipContext } from 'src/context/RelationshipContext';

export default function BirthdateSetup() {
  const navigate = useNavigate();
  const { relationship, updateRelationship } = useContext(RelationshipContext);

  const [isLoading, setIsLoading] = useState(false);

  const [birthdate, setBirthdate] = useState<Date | null>(null);

  const [month, setMonth] = useState<number | null>(null);
  const [day, setDay] = useState<number | null>(null);
  const [year, setYear] = useState<number | null>(null);

  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  function isValidDate(year: number, month: number, day: number): boolean {
    const date = new Date(year, month - 1, day); // month - 1 to adjust for 0-indexed months

    // Check if the date is valid
    const isValid = date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;

    if (!isValid) {
      return false;
    }

    // Get today's date and create a new Date object for 10 months from now
    const today = new Date();
    const maxDate = new Date(today);
    maxDate.setMonth(today.getMonth() + 10);

    // Check if the date is in the future but not more than 10 months from today
    if (date >= maxDate) {
      setErrorMsg('Invalid date. Date can be anytime.');
      return false;
    }

    return true;
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    if (!year || !month || !day) return;

    try {
      if (!isValidDate(year, month, day)) {
        alert('Invalid date. Date can be anytime.');
        return;
      }

      const dueDate = birthdate ?? new Date(year, month - 1, day);

      updateRelationship({ ...relationship, dueDate });

      if (!relationship?.fromSignup) {
        const response = await createRelationsApiHandler({
          name_of_relation: relationship?.relationshipType || '',
          due_date: dueDate,
          username: relationship?.username || '',
          name: relationship?.name || '',
          icon_color: Math.floor(Math.random() * 16777215)
            .toString(16)
            .padStart(6, '0'),
        });

        if (response.error) {
          alert(response.error);
          return;
        }
      } else {
        navigate('/password-setup');
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="px-6 py-11 flex flex-col items-center justify-between relative h-full">
      <ArrowLeftIcon className="size-6 absolute self-start md:hidden cursor-pointer" onClick={() => navigate(-1)} />

      <div className="pt-20 w-full max-w-xl flex flex-col justify-between md:justify-center flex-grow">
        <div className="">
          <div className="flex flex-col items-center md:items-start gap-2">
            <h1 className="text-2xl md:text-4xl text-black font-bold">
              Start <span className="capitalize">{relationship?.name}&apos;s</span> story
            </h1>

            <div className="w-[100px] h-0.5 bg-black/10 rounded-xl block md:hidden">
              <div className="bg-success h-full w-4/5 rounded-xl"></div>
            </div>
          </div>

          <p className="text-xl mt-7 block md:hidden">
            When was or when will <span className="capitalize">{relationship?.name}</span> be born?
            <br />
            <br />
            You’ll receive more relevant daily prompts. If <span className="capitalize">{relationship?.name}</span>{' '}
            isn’t born yet, you can use the due date and change to the exact date in settings
          </p>

          <div className="mt-5 hidden md:flex flex-col gap-5 md:text-xl">
            <div className="">
              <p className="">
                When is <span className="capitalize">{relationship?.name}&apos;s</span> birthday?
              </p>

              <div className="flex items-center gap-9 mt-2">
                <Input
                  type="text"
                  placeholder="Month"
                  max={12}
                  value={month || ''}
                  onChange={(e) => {
                    const inputVal = e.target.value;
                    const regex = /^-?\d*$/;

                    if (regex.test(inputVal)) {
                      const numberVal = Number(inputVal);
                      numberVal === 0 ? setMonth(null) : setMonth(numberVal);
                    }
                  }}
                  inputMode="numeric"
                  pattern="[0-9]*"
                  className="border border-neutral-300 text-neutral-300 placeholder:text-neutral-300 rounded-3xl text-center px-4 py-6 text-xl w-[111px] h-[57px]"
                />

                <Input
                  type="text"
                  placeholder="Day"
                  value={day || ''}
                  max={31}
                  onChange={(e) => {
                    const inputVal = e.target.value;
                    const regex = /^-?\d*$/;

                    if (regex.test(inputVal)) {
                      const numberVal = Number(inputVal);
                      numberVal === 0 ? setDay(null) : setDay(numberVal);
                    }
                  }}
                  inputMode="numeric"
                  pattern="[0-9]*"
                  className="border border-neutral-300 text-neutral-300 placeholder:text-neutral-300 rounded-3xl text-center px-4 py-6 text-xl w-[111px] h-[57px]"
                />

                <Input
                  type="text"
                  placeholder="Year"
                  value={year || ''}
                  onChange={(e) => {
                    const inputVal = e.target.value;
                    const regex = /^-?\d*$/;

                    if (regex.test(inputVal)) {
                      const numberVal = Number(inputVal);
                      numberVal === 0 ? setYear(null) : setYear(numberVal);
                    }
                  }}
                  inputMode="numeric"
                  pattern="[0-9]*"
                  className="border border-neutral-300 text-neutral-300 placeholder:text-neutral-300 rounded-3xl text-center px-4 py-6 text-xl w-[136px] h-[57px]"
                />
              </div>
            </div>

            <p className="">
              You’ll receive more relevant daily prompts. If <span className="capitalize">{relationship?.name}</span>{' '}
              isn’t born yet, you can use the due date and change to the exact date in settings
            </p>
          </div>
        </div>

        <div className="block md:hidden">
          <DatePicker
            placeholderText="MM/DD/YYYY"
            selected={birthdate}
            onChange={(value) => setBirthdate(value)}
            className="w-full text-center text-black placeholder:text-black focus-within:outline-none"
          />
        </div>

        <div className="flex justify-center w-full md:hidden">
          <Button
            disabled={!birthdate || isLoading}
            className="py-4 px-12 w-full text-white text-xl rounded-3xl bg-primary-purple hover:bg-primary-purple/80 disabled:bg-primary-purple/50"
            onClick={handleSubmit}
          >
            Continue
          </Button>
        </div>
      </div>

      <div className="hidden md:flex self-end">
        <Button
          disabled={day === null || month === null || year === null || isLoading}
          className="py-4 px-12 text-white text-xl rounded-3xl bg-primary-purple hover:bg-primary-purple/80 disabled:bg-primary-purple/50"
          onClick={handleSubmit}
        >
          Step 4 of {relationship?.fromSignup ? '5' : '4'}
        </Button>
      </div>
    </div>
  );
}
